import React from "react";
import { graphql, Link } from "gatsby";
// import {Divider, Descriptions} from "antd";
// import Layout from "../components/headerFooterLayout"
import { get } from "lodash";
import DiscographyShowPage from "../components/DiscographyShowPage";
import Img, { FluidObject } from "gatsby-image";

const formatData = (data) => {
  return {
    title: data.item.title,
    artwork: (
      <Img
        fluid={
          get(data, "item.artwork.childImageSharp", data.defaultImage).fluid
        }
      />
    ),
    songs: data.item.songs,
    date: data.item.date,
  };
};

export default ({ location, data }) => {
  return <DiscographyShowPage {...formatData(data)} location={location} />;
};

export const query = graphql`
  fragment AlbumArtSharp on ImageSharp {
    fluid(maxWidth: 300, maxHeight: 300) {
      ...GatsbyImageSharpFluid
    }
  }

  query($slug: String!) {
    item: discography(slug: { eq: $slug }) {
      slug
      date
      songs {
        slug
        title
      }
      title

      artwork {
        childImageSharp {
          ...AlbumArtSharp
        }
      }
    }

    defaultImage: imageSharp(
      original: { src: { regex: "/.*/album-default/" } }
    ) {
      ...AlbumArtSharp
    }
  }
`;
