import React from "react"
import { Link } from "gatsby"
import { Divider, Descriptions } from "antd"
import Layout from "../../../components/headerFooterLayout"
import Img from "gatsby-image"
import { DiscographyShowPageProps } from "@richsoni/gatsby-theme-core/src/lib"

export default ({
  title,
  artwork,
  songs,
  date,
  location,
}: DiscographyShowPageProps) => {
  return (
    <Layout
      style={{ padding: "24px", background: "#fff" }}
      currentPath={location.pathname}
    >
      <div style={{ width: 300 }}>{artwork}</div>
      <Descriptions title={title}>
        <Descriptions.Item label="Publish Date" key="Publish Date">
          {date || "Not Listed"}
        </Descriptions.Item>
      </Descriptions>
      <Divider dashed />
      <h3>Tracks:</h3>
      <ol>
        {songs.map(track => (
          <li key={track.slug}>
            <Link to={track.slug}>{track.title}</Link>
          </li>
        ))}
      </ol>
    </Layout>
  )
}
